import React from "react"
import {Link} from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import "./../sass/main.scss";
import Helmet from "react-helmet";
import gnbLogo from "../images/NB-LOGO.svg";

const NotFoundPage = () => (
    <Layout>
        <Helmet
            bodyAttributes={{
                class: 'positions-page'
            }}
        />
        <SEO description="New Brunswick Job Connector" title="New Brunswick Job Connector"/>
        <div className={"b-gradient"}></div>
        <div className={"container-xxl"}>
            <div className={"r-c-lg ac jsb"}>
                <Link to="/"><img className={"my4"} width="216" height="93" src={gnbLogo} alt="GNB Logo"/></Link>
                <div className={"c jc afe ac-lg"}>
                </div>
            </div>
            <hr className={"mb5"}></hr>
        </div>
        <div className={"container-xxl tac-lg"}>
            <h2 className={"py4"}>404: Page Not Found / Page non trouvée</h2>
            <Link className={"mb5"} to="/">
                <button>Go Back / Retourner</button>
            </Link>

        </div>
        <div className={"container-xxl footer mt6"}>
            <hr></hr>
            <div className={"r-c-lg jsb ac"}>
                <div className={"r"}>
                    <p className={"mb0 tac-sm"}>All content © Government of New Brunswick. All rights reserved.</p>
                </div>
                <div className={"r-c-sm mt4-lg"}>
                    <Link className={"px3 tac-sm my2-sm"} target={"_blank"}
                          to="https://www2.gnb.ca/content/gnb/en.html?_ga=2.172893923.1322334766.1613581688-1203811668.1613581688">
                        Feedback
                    </Link>
                    <Link className={"px3 tac-sm my2-sm"} target={"_blank"}
                          to="https://www2.gnb.ca/content/gnb/en/corporate/connect.html">
                        Social Media
                    </Link>
                    <Link className={"px3 tac-sm my2-sm"} target={"_blank"}
                          to="https://www2.gnb.ca/content/gnb/en/admin/privacy.html">
                        Privacy
                    </Link>
                    <Link className={"px3 tac-sm my2-sm"} target={"_blank"}
                          to="https://www2.gnb.ca/content/gnb/en/admin/disclaimer.html">
                        Disclaimer
                    </Link>
                </div>
            </div>
        </div>

    </Layout>
)

export default NotFoundPage
